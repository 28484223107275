const AppSettings = {
    appTitle: 'Nomadis',
    mapsalgerie: '34.442277, 3.492232',
	   devise : 'Dinars',
       millimes: 'millimes',
       decimal : 3,

    show_poids : true,
    zoomalgerie : 7,
    keymaps :'AIzaSyBD2HxGeV6JrleH2XhcNb5lyN1B3WkRAls&v=3&libraries=geometry',
	 apiUrl:'https://api.chargui.clediss.online/api/v1/',
	 herewego : {
                app_id : 'VxpPmyKjSh1FcbqCq7d7',
                app_code : 'sjYx8madd1LAp_IP0Qo1GQ'
        },
        //using mapbox accesstocken
        leaflet : {
                access_token : 'pk.eyJ1IjoiY2xlZGlzc25vbWFkaXMiLCJhIjoiY2p0b2w1MHJqMDU2czQ1cG5tY3Bu$'
        },
       lang : 'fr_FR',
        //lang : 'ar_AR',
        //SERVER NODEJS AND SOCKET
        USING_SOCKET_AND_REDIS : false,
        BROADCAST_PORT : 3444,
        ADRESS_SERVER : 'http://localhost'
};
export default AppSettings;

